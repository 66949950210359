import React, { Component } from 'react';
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch
} from 'react-router-dom';
import { connect } from 'react-redux';
import Loadable from 'react-loadable';
import { Affix } from 'antd';
import { onWindowSizeChange } from './actions/event/EventActions';
import Menu from './components/menu/Menu';
import Loading from './components/commons/loading/Loading';
import { throttle } from './utils/FunctionUtils';
import ConfiguredPage from './pages/configuredPage/ConfiguredPage'
import ScrollToTop from './components/commons/scrollTop/ScrollTop';
import { getIsServerError } from './reducers/commons/commonsReducers';
import { getWindowSize } from './reducers/event/eventReducer';
import IndiceDetailAsync from './pages/indiceDetail/IndiceDetailContainer'
//CIS menu config here 
import './App.less'
import routes from './routes';
import LoginContainer from './components/cisLogin/LoginContainer'
import LogoBar from './components/logoBar/LogoBar';
// import './components/menu/Menu.less';


const ErrorPageAsync = Loadable({
	loader: () => import('./pages/errorPage/ErrorPage'),
	loading: Loading
})


const ServerErrorAsync = Loadable({
	loader: () => import('./pages/errorPage/ServerError'),
	loading: Loading
})

// const IndiceDetailAsync = Loadable({
// 	loader: () => import('./pages/indiceDetail/IndiceDetailContainer'),
// 	loading: Loading
// })

const IndicesPageAsync = Loadable({
	loader: () => import('./pages/indicesGrid/IndicesGridContainer'),
	loading: Loading
})

const NewsAnnouncementsAsync = Loadable({
	loader: () => import('./pages/newsAnnouncements/NewsAnouncements'),
	loading: Loading
})

const NewsListAsync = Loadable({
	loader: () => import('./pages/newsAnnouncements/NewsList'),
	loading: Loading
})

const DocHome = Loadable({
	loader: () => import('./cisDoc/views/home'),
	loading: Loading
})

const EmailIndicesDetailContainerAsync = Loadable({
	loader: () => import('./pages/indiceDetail/EmailIndicesDetailContainer.jsx'),
	loading: Loading
})


class App extends Component {

	constructor(props) {
		super(props)
		this.handleOnSize = throttle(this.handleOnSize.bind(this), 500, {})
		this.state = {
			collapsed: true,
			isCookieNotificationOpen: false
		}
	}

	componentDidMount() {
		window.onresize = this.handleOnSize;

	}
	componentWillUnmount() {
		window.onresize = null;
	}
	handleOnSize(e) {
		const { onWindowSizeChange } = this.props
		onWindowSizeChange(window.innerWidth);
	}

	toggleBtn() {
		this.setState({ ...this.state, collapsed: !this.state.collapsed });
	}

	clickMobileLogo = () => {
		window.$('#menuCollapse').collapse('hide');
		this.setState({ searchCollapsed: true, collapsed: true });
	}

	clickMobileLogo = () => {
		window.$('#menuCollapse').collapse('hide');
		this.setState({ searchCollapsed: true, collapsed: true });
	}

	toggleCookieNotification = (open) => {
		this.setState({
			isCookieNotificationOpen: open
		})
	}

	searchHide() {
		this.setState({ searchCollapsed: true });
	}
	render() {
		const pathName = window.location.pathname;
		const showMenu = !pathName.includes('/insights/print/') && !pathName.includes('/maintenance') && !pathName.includes('/preview/content/') && !pathName.includes('/pages/') && pathName !== '/';
		const { isServerError, windowSize } = this.props
		const { collapsed, isCookieNotificationOpen } = this.state;
		let component
		return isServerError ? (
      <ServerErrorAsync />
    ) : (
      <div className="App" style={{ height: "100%" }}>
        <Router>
          <ScrollToTop>
            {/* <Route render={(props) => {
								return windowSize <= 767 ?
									<Affix>
										<LogoBar collapsed={collapsed} toggleBtn={this.toggleBtn} clickMobileLogo={this.clickMobileLogo} searchHide={this.searchHide} />
									</Affix> :
									<LogoBar collapsed={collapsed} toggleBtn={this.toggleBtn} clickMobileLogo={this.clickMobileLogo} searchHide={this.searchHide} />
							}}/> */}
            <LogoBar show={pathName.split("/")[1] !== "citicis"} />

            <Route
              render={(props) => {
                let showMenu = true;
                component = <Menu showMenu={showMenu} />;
                switch (props.location.pathname.split("/")[1]) {
                  case "cis-home":
                  case "cis-terms-conditions":
                  case "cis-privacy":
                  case "cis-disclaimer":
                  case "cis-help":
                  case "citicis":
                    showMenu = false;
                    component = null;
                    break;
                }

                if (props.location.pathname.split("/")[2] === "detail") {
                  showMenu = false;
                  component = null;
                }
                if (
                  props.location.pathname.split("/")[1] === "cis-home" &&
                  props.location.pathname.split("/").length > 2
                ) {
                  showMenu = true;
                  component = <Menu showMenu={showMenu} />;
                }
                return component;
              }}
            />
            <Switch>
              {routes}
              <Route
                exact
                path="/"
                render={() => <Redirect to="/cis-home" />}
              />
              <Route exact path="/cis-home" component={LoginContainer} />
              <Route exact path="/error-page" component={ErrorPageAsync} />
              <Route
                exact
                path="/our-approach/:pathName"
                render={(props) => {
                  return (
                    <Redirect
                      to={`/our-products/${props.match.params.pathName}`}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/contact-us"
                render={(props) => <Redirect to="/contact" />}
              />
              <Route
                exact
                path="/indice/:index/:regionId/:investorTypeId"
                component={IndiceDetailAsync}
              />
              <Route
                exact
                path="/indices/:regionId/:investorTypeId"
                component={IndicesPageAsync}
              />
              <Route
                exact
                path="/news/:regionId/:investorTypeId"
                component={NewsListAsync}
              />
              <Route
                exact
                path="/news/:newsId/:regionId/:investorTypeId"
                component={NewsAnnouncementsAsync}
              />
              <Route exact path="/citicis/cis-doc/*" component={DocHome} />
              <Route
                exact
                path="/cis-preview/:pageId"
                component={ConfiguredPage}
              />
              <Route
                exact
                path="/indice/detail/:index/:regionId/:investorTypeId"
                component={EmailIndicesDetailContainerAsync}
              />
              <Redirect to="/cis-home" />
            </Switch>

            <Route
              render={(props) => {
                if (
                  props.location.pathname === "/" ||
                  props.location.pathname === "/cis-home" ||
                  props.location.pathname === "/cis-home/" ||
                  props.location.pathname.includes(
                    "/citicis/cis-doc/html/app.html"
                  )
                )
                  return null;
                return <ConfiguredPage pageName="CIS-Footer" />;
              }}
            />
          </ScrollToTop>
        </Router>
      </div>
    );
	}
}

const mapStateToProps = (state) => ({
	isServerError: getIsServerError(state),
});

const mapDispatchToProps = {
	onWindowSizeChange,
}

export default connect(mapStateToProps, mapDispatchToProps)(App);